import {
	Autocomplete,
	Box,
	CircularProgress,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { ICountries, INewImage, INewProduct, IState } from '../../../../api/interfaces/interfaces';
import { getProductsConditions } from '../../../../api/productsApi';
import { getArgentinianStates, getMunicipalitiesByState } from '../../../../api/argentinaGobApi';
import { getAllCountries } from '../../../../api/utilsApi';

interface StepThreeProps {
	handleChangeForm: (fieldName: keyof INewProduct, value: string | number | INewImage[] | null) => void;
	newProduct: INewProduct;
}

export const StepFour = (props: StepThreeProps) => {
	const { handleChangeForm, newProduct } = props;
	const [isLoadingConditions, setLoadingConditions] = useState<boolean>(false);
	const [isLoadingStates, setLoadingStates] = useState<boolean>(false);
	const [isLoadingCountries, setLoadingCountries] = useState<boolean>(false);
	const [showInputText, setShowInputText] = useState<boolean>(!!newProduct.city);
	const [isLoadingMunicipalities, setLoadingMunicipalities] = useState<boolean>(false);
	const [conditions, setConditions] = useState<string[]>([]);
	const [states, setStates] = useState<IState[]>([]);
	const [municipalities, setMunicipalities] = useState<IState[]>([]);
	const [countries, setCountries] = useState<ICountries[]>([]);

	const loadStates = async () => {
		const states = await getArgentinianStates('id,nombre');
		// Ordenar los estados alfabeticamente
		const sortedStates = states.sort((a, b) => a.nombre.localeCompare(b.nombre));
		setStates(sortedStates);
		setLoadingStates(false);
	};

	const loadMunicipalities = async (value: string) => {
		const muni = await getMunicipalitiesByState(value);
		// Ordenar los estados alfabeticamente
		const sortedMunicipalities = muni.sort((a, b) => a.nombre.localeCompare(b.nombre));
		setMunicipalities([...sortedMunicipalities, { id: '00', nombre: 'Otro' }]);
		setLoadingMunicipalities(false);
	};
	const loadAndClearMunicipalities = (value: string) => {
		setLoadingMunicipalities(true);
		loadMunicipalities(value);
		handleChangeForm('city', '');
	};

	const loadCondtions = async () =>
		await getProductsConditions().then((cond) => {
			setConditions(cond);
			setLoadingConditions(false);
		});

	const loadCountries = async () => {
		await getAllCountries().then((resp) => {
			setCountries(resp.sort((a,b)=> a.name.common.trim().localeCompare(b.name.common.trim())));
			setLoadingCountries(false);
		});
	};
	const handleMunicipalitySelected = (value: string) => {
		if (value !== 'Otro') {
			handleChangeForm('city', value);
			setShowInputText(false);
		} else {
			handleChangeForm('city', value);
			setShowInputText(true);
		}
	};

	useEffect(() => {
		setLoadingConditions(true);
		setLoadingStates(true);
		setLoadingCountries(true);
		loadCondtions();
		loadStates();
		loadMunicipalities(newProduct.state!);
		loadCountries();
	}, []);

	const builderOptions = [
		{ code: 1, label: 'Original' },
		{ code: 2, label: 'Importado' },
		{ code: 3, label: 'Nacional' },
	];
	return (
		<Grid
			item
			xs={12}
			sx={{
				marginTop: 2,
				mt: 5,
				width: { xs: '90%', sm: '80%', md: '50%' },
				margin: 'auto',
			}}>
			<FormControl fullWidth sx={{ minWidth: 120 }} style={{ marginTop: '5%' }}>
				<Autocomplete
					disablePortal
					id="combo-box-demo"
					options={conditions}
					getOptionLabel={(condition) => condition}
					value={conditions.find((condition) => condition === newProduct.condition) || null}
					onChange={(event, newValue) => handleChangeForm('condition', newValue!)}
					disabled={isLoadingConditions}
					renderInput={(params) => (
						<TextField
							{...params}
							label="Condición / Estado *"
							InputProps={{
								...params.InputProps,
								style: { borderRadius: '10px' },
								endAdornment: (
									<>
										{isLoadingConditions ? <CircularProgress size={24} color="inherit" /> : null}
										{params.InputProps.endAdornment}
									</>
								),
							}}
						/>
					)}
					sx={{ mb: '3%' }}
				/>
				<Box sx={{ position: 'relative' }}>
					<FormControl fullWidth>
						<InputLabel id="demo-simple-select-label">Línea del modelo</InputLabel>
						<Select
							sx={{ borderRadius: '10px' }}
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={newProduct.buildingType}
							label="Linea del modelo"
							onChange={({ target: { value } }) => handleChangeForm('buildingType', value)}>
							{builderOptions.map((opt, index) => (
								<MenuItem value={opt.label} key={index}>
									{opt.label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Box>
				{newProduct.buildingType === 'Importado' && (
					<Box sx={{ position: 'relative', }}>
						<Box
							sx={{
								position: 'absolute',
								top: '50%',
								left: '-10px',
								transform: 'translateY(-50%)',
								width: 0,
								height: 0,
								borderStyle: 'solid',
								borderWidth: '5px 0 5px 5px',
								borderColor: 'transparent transparent transparent red',
								zIndex: 1,
							}}
						/>
						<Autocomplete
							disablePortal
							id="combo-box-demo"
							options={countries}
							getOptionLabel={(country) => country.translations.spa.common}
							value={
								countries.find((country) => country.translations.spa.common === newProduct.buildingCountry) || null
							}
							onChange={(event, newValue) => handleChangeForm('buildingCountry', newValue?.translations.spa.common!)}
							disabled={isLoadingCountries}
							renderInput={(params) => (
								<TextField
									{...params}
									label="País de fabricación"
									InputProps={{
										...params.InputProps,
										style: { borderRadius: '10px' },
										endAdornment: (
											<>
												{isLoadingCountries ? <CircularProgress size={24} color="inherit" /> : null}
												{params.InputProps.endAdornment}
											</>
										),
									}}
								/>
							)}
							sx={{ mb: '3%', mt: '3%' }}
						/>
					</Box>
				)}

				<Autocomplete
					// disablePortal
					id="combo-box-demo"
					// popupIcon={<span   > </span>}
					options={states}
					getOptionLabel={(state) => state.nombre}
					value={states.find((state) => state.nombre === newProduct.state) || null}
					onChange={(event, newValue) => {
						handleChangeForm('state', newValue?.nombre!);
						loadAndClearMunicipalities(newValue?.nombre!);
					}}
					disabled={isLoadingStates}
					renderInput={(params) => (
						<TextField
							{...params}
							label="Provincia"
							InputProps={{
								...params.InputProps,
								style: { borderRadius: '10px'},
								endAdornment: (
									<>
										{isLoadingStates ? <CircularProgress size={24} color="inherit" /> : null}
										{params.InputProps.endAdornment}
									</>
								),
							}}
						/>
					)}
					sx={{ mb: '3%', mt: '3%' }}
				/>
				{newProduct.state && (
					<>
						<Autocomplete
							// disablePortal
							id="combo-box-demo"
							options={municipalities}
							getOptionLabel={(municipality) => municipality.nombre}
							loading={isLoadingMunicipalities}
							loadingText="Cargando..."
							// isOptionEqualToValue={()=> }
							value={
								(!isLoadingMunicipalities &&
									municipalities.find((municipality) =>
										!showInputText ? municipality.nombre === newProduct.city : municipality.nombre === 'Otro',
									)) ||
								null
							}
							onChange={(event, newValue) => handleMunicipalitySelected(newValue?.nombre!)}
							disabled={isLoadingMunicipalities}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Ciudad"
									key={'key2'}
									InputProps={{
										...params.InputProps,
										style: { borderRadius: '10px' },
										endAdornment: (
											<>
												{isLoadingMunicipalities ? <CircularProgress size={24} color="inherit" /> : null}
												{params.InputProps.endAdornment}
											</>
										),
									}}
								/>
							)}
							sx={{ mb: '3%' }}
						/>
						{showInputText && (
							<TextField
								key={'key'}
								label="Especificar"
								onChange={({ target: { value } }) => handleChangeForm('city', value)}
								defaultValue={newProduct.city}
							/>
						)}
					</>
				)}
			</FormControl>
		</Grid>
	);
};
