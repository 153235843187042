import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Grid,
  Modal,
  TableContainer,
  Typography,
} from "@mui/material";
import { Accident } from "../../../../api/interfaces/interfaces";
import { CreateAccidentStepper } from "./CreateAccidentStepper";
import { DataTable } from "./DataTable";
import { SearchBox } from "./SearchBox";
import { StatsBox } from "./StatsBox";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "black",
  color:'white',
  boxShadow: 24,
  borderRadius: 2,
};

interface AccidentViewProps {
  accidentList: Accident[];
  setAccidentList: React.Dispatch<React.SetStateAction<Accident[]>>;
  loading: boolean;
  error: string | null;
}

export const AccidentView = (props: AccidentViewProps) => {
  const { accidentList, setAccidentList, loading, error } = props;
  const [filteredAccidents, setFilteredAccidents] = useState<Accident[]>([]);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setFilteredAccidents(accidentList);
  }, [accidentList]);

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleOpen}
        sx={{
          position: 'absolute', right: 300, top: 200, backgroundColor: '#a70d15', color: 'white',
          '&:hover': {
            backgroundColor: '#8a1218',
            borderColor: '#8a1218',
          },
        }}
      >
        Cargar siniestro
      </Button>

      <Modal open={modalOpen} onClose={handleClose}>
        <Box sx={style}>
          <Typography variant="h6" marginBottom={2} style={{display: 'flex', justifyContent:'center'}}>
            Cargar nuevo siniestro
          </Typography>

          <Grid container spacing={2} marginTop={2}>
            <CreateAccidentStepper handleClose={handleClose} setAccidentList={setAccidentList} />
          </Grid>
        </Box>
      </Modal>

      <Grid container spacing={2} marginTop={2}>
        <StatsBox filteredAccidents={filteredAccidents} />
      </Grid>

      <TableContainer sx={{ marginTop: 4, padding: 2 }}>
        <SearchBox accidentList={accidentList} setAccidentList={setAccidentList} />

        <DataTable accidentList={accidentList} setAccidentList={setAccidentList} />
      </TableContainer>
    </>
  );
};
