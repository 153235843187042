import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useState } from 'react'
import { Accident } from '../../../../api/interfaces/interfaces';

interface AccidentDetailsProps {
    openModal: boolean;
    handleCloseModal: () => void;
    selectedAccident: Accident | null;
}

export const AccidentDetails = (props: AccidentDetailsProps) => {
    const { openModal, handleCloseModal, selectedAccident } = props;

    return (
        <Dialog open={openModal} onClose={handleCloseModal}>
            <Box sx={{ backgroundColor: 'black', color: 'white', width: 500, border: '1px solid #a70d15' }}>
                <DialogTitle>Detalles del Siniestro</DialogTitle>
                <DialogContent>
                    {selectedAccident && (
                        <>
                            <div><strong>Número de Siniestro:</strong> {selectedAccident.accidentNumber}</div>
                            <div><strong>Fecha:</strong> {selectedAccident.createdAt}</div>
                            <div><strong>Descripción:</strong> {selectedAccident.description}</div>
                            <div><strong>Estado:</strong> {selectedAccident.status}</div>
                            <div><strong>Asegurado:</strong> {selectedAccident.insuredID}</div>
                            <div><strong>Denuncia:</strong> {selectedAccident.files?.accident}</div>
                            <div><strong>Orden de reparación:</strong> {selectedAccident.files?.repairOrder}</div>
                            <div><strong>Fotos del vehículo:</strong> {selectedAccident.files?.photos}</div>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} sx={{
                        backgroundColor: '#a70d15', color: 'white', marginRight: 4, marginBottom: 2, '&:hover': {
                            backgroundColor: '#8a1218',
                            borderColor: '#8a1218',
                        },
                    }}>
                        Cerrar
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    )
}
