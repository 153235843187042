import { Grid, TextField } from "@mui/material";
import {
  IImage,
  INewProduct,
} from "../../../../api/interfaces/interfaces";

interface StepOneProps {
  handleChangeForm: (
    fieldName: keyof INewProduct,
    value: string | number | IImage[] | null
  ) => void;
  newProduct: INewProduct;
  // editedProductId: string;
}

export const StepOne = (props: StepOneProps) => {
  const { handleChangeForm, newProduct } = props;

  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/\./g, ''); 
    const numberValue = Number(value);
    handleChangeForm('price', numberValue);
  };

  const formatNumber = (num: number) => {
    if (num === 0) return '';
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  return (
    <>
      <Grid
        item
        xs={12}
        sx={{
          mt: 5,
          width: { xs: "90%", sm: "80%", md: "100%", xl: "100%" },
          margin: "auto",
        }}
      >
        <TextField
          label="Nombre del repuesto *"
          type="text"
          fullWidth
          name="productName"
          value={newProduct.name}
          onChange={(event) => handleChangeForm("name", event.target.value)}
          InputProps={{ style: { borderRadius: '10px' } }}
          sx={{
            // borderRadius: '10px',
            // border: 'thin solid grey',
            // backgroundColor: "#eef3f8",
            marginTop: "2%",
          }}
        //disabled={isLoading}
        />
        <TextField
          label="Descripción del repuesto *"
          type="text"
          fullWidth
          multiline
          rows={6}
          name="productDescription"
          value={newProduct.description}
          onChange={(event) =>
            handleChangeForm("description", event.target.value)
          }
          InputProps={{ style: { borderRadius: '10px' } }}
          sx={{
            borderRadius: 3,
            // backgroundColor: "#eef3f8",
            marginTop: "2%",
            width: "10s0%",
          }}
        // disabled={isLoading}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          marginTop: 2,
          marginBottom: 10,
          width: { xs: "90%", sm: "80%", md: "100%", xl: "100%" },
          margin: "auto",
        }}
      >
        <TextField
          label="Precio del repuesto *"
          type="text"
          fullWidth
          name="price"
          value={newProduct.price !== 0 ? formatNumber(newProduct.price) : ""}
          onChange={handlePriceChange}
          inputProps={{ min: 0, maxLength: 10 }}
          InputProps={{ style: { borderRadius: '10px' } }}
          sx={{
            borderRadius: 3,
            // backgroundColor: "#eef3f8",
            marginTop: "2%",
            width: "100%",
          }}
        // disabled={isLoading}
        />
      </Grid>
    </>
  );
};
