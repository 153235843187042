import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.withCredentials = true;
axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.response.use(
	(response) => response, // Si la respuesta es exitosa, devolverla tal cual
	async (error) => {
	  if (error.response && error.response.status === 401) {
		try {
		  // Intentar renovar el token
		  if(error?.config?.url.includes("checkSession")){
			const tokenResponse = await axios.post("auth/refresh-token", {}, { withCredentials: true });

			if (tokenResponse.status === 201) {
				// Reintentar la solicitud original
				return window.location.reload();
			  } else {
				// Si la renovación falla, redirigir al login
				if (window.location.pathname !== "/login") {
				  window.location.href = "/login";
				  localStorage.setItem("isAuthenticated", "false");
				}
			  }
		  }
		} catch (refreshError) {
		  // Si la renovación falla, redirigir al login
		  console.error("Error al renovar el token:", refreshError);
		  if (window.location.pathname !== "/login") {
			window.location.href = "/login";
			localStorage.setItem("isAuthenticated", "false");
		  }
		}
	  }
  
	  // Si no es un 401 o no se pudo renovar el token, rechazar el error
	  return Promise.reject(error);
	}
  );

export const axiosService = axios;
