import styled from '@emotion/styled';
import { InputAdornment, InputBase, Paper } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import { getAllInsured, getInsuredBySearch } from '../../../../api/insuredApi';
import { Insured } from '../../../../api/interfaces/interfaces';

const SearchBoxStyles = styled(Paper)({
    display: 'flex',
    width: '40%',
    alignItems: 'center',
    marginBottom: 16,
    padding: '4px 16px',
    borderRadius: 20,
    border: '1px solid #cf1717',
});

interface SearchBoxProps {
    insuredList: Insured[];
    setInsuredList: React.Dispatch<React.SetStateAction<Insured[]>>;
}

export const SearchBox = (props: SearchBoxProps) => {
    const { insuredList, setInsuredList } = props;
    const [search, setSearch] = useState('');
    const debounceSearch = useCallback(() => {
        const handler = setTimeout(async () => {
            if (search.trim()) {
                console.log('ingreso 162')
                const data = await getInsuredBySearch(search, 10, 1);
                if (data) {
                    console.log('ingreso 165')
                    setInsuredList(data);
                    console.log('la data actual es', JSON.stringify(insuredList));
                }
            } else {
                const allData = await getAllInsured();
                setInsuredList(allData);
            }
        }, 300);
        return () => clearTimeout(handler);
    }, [search]);

    useEffect(() => {
        debounceSearch();
    }, [search, debounceSearch]);

    return (
        <SearchBoxStyles style={{ backgroundColor: 'black' }}>
            <InputBase
                style={{ backgroundColor: 'black', color: 'white' }}
                placeholder="Buscar siniestro, póliza o estado"
                fullWidth
                value={search}
                onChange={(e) => setSearch(e.target.value.trim())}
                startAdornment={
                    <InputAdornment position="start">
                        <SearchIcon style={{ color: 'white' }} />
                    </InputAdornment>
                }
            />
        </SearchBoxStyles>
    )
}
