import { Avatar, Button, Card, Grid, Typography } from '@mui/material';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { SellerRating } from './SellerRating';
import { getRelevantSellerDataByID } from '../../../api/userApi';
import { useEffect, useState } from 'react';
import { IRelevantSellerData } from '../../../api/interfaces/interfaces';
import { dateFormat } from '../../../functions/CommonFunctions';
import { useNavigate } from 'react-router-dom';

interface SellerDescriptionProps {
	ownerID: string;
}

export const SellerDescription = (props: SellerDescriptionProps) => {
	const { ownerID } = props;
	const navigate = useNavigate();
	const [relevanteSellerData, setRelevanteSellerData] = useState<IRelevantSellerData>();

	async function getQuestions() {
		const result = await getRelevantSellerDataByID(ownerID);
		setRelevanteSellerData(result);
	}

	useEffect(() => {
		ownerID && getQuestions();
	}, [ownerID]);

	const capitalizeFirstLetter = (text: string | undefined) => {
		if (!text) return '';
		return text
			.toLowerCase()
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	};
	const formattedSellerName = capitalizeFirstLetter(relevanteSellerData?.sellerName); // convierte todos los nombres de usuario a Este Formato


	return (
		<Card
			sx={{
				borderRadius: '4px',
				justifyContent: 'center',
				backgroundColor: '#101010',
				padding: 1
			}}>
			<Grid container alignItems="center" spacing={1} marginTop="1px" >
				<Grid item xs={8} display='flex'>
					<Avatar sx={{ color: 'black', backgroundColor: 'white', verticalAlign: 'middle', mr: '.5rem' }} src={relevanteSellerData?.image?.imageData!} />
					<Typography textAlign="left" whiteSpace='nowrap' textOverflow='ellipsis'  color="#f1f1f1" fontSize="1.03rem" fontFamily="Roboto" sx={{ display: 'flex', alignItems: 'center' }} >
						{formattedSellerName}
					</Typography>
				</Grid>



				<Typography marginLeft={1} textAlign="left" color="#f1f1f1" fontSize=".9rem" fontFamily="Roboto" sx={{ mt: '.5rem' }}>
					<strong>Primer producto publicado: </strong>
					{dateFormat(relevanteSellerData?.firstPublishDate!)}
				</Typography>
			</Grid>


			<Grid container alignItems="center" spacing={1} marginTop="3px" >

				<Grid item xs={11} display='flex' sx={{ marginLeft: -1 }}>
					<Typography marginLeft={1} textAlign="left" color="#f1f1f1" fontSize=".9rem" fontFamily="Roboto">
						<strong>Cantidad de Productos: </strong> {relevanteSellerData?.productCount}
					</Typography>
				</Grid>
			</Grid>


			<SellerRating avgResponseTime={relevanteSellerData?.avgResponseTime!} />



			<Typography align="center">
				<Button
					variant="contained"
					color="secondary"
					size="medium"
					sx={{
						marginTop: 2,
						paddingX: 2,
						paddingY: .5,
						borderRadius: '14px', 
						backgroundColor: '#1565c0',  
						color: '#ffffff',  
						textTransform: 'none',  // Mantener el texto en formato normal (no el formato button)
						fontWeight: 'bold',  
						fontSize: '1rem',  
						'&:hover': {
							backgroundColor: '#1e88e5',  
						},
						'& .MuiButton-startIcon': {
							marginRight: 1,  
						}
					}}
					onClick={() => navigate(`/sellerProfile/${ownerID}`)}
					startIcon={<RemoveRedEyeOutlinedIcon />}
				>
					Ver perfil
				</Button>
			</Typography>

		</Card>
	);
};
