import styled from '@emotion/styled';
import { Table, TableBody, TableCell, TableHead, TableRow, Dialog, DialogActions, DialogContent, DialogTitle, Button, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Accident } from '../../../../api/interfaces/interfaces';
import { deleteAccidentById, getAllAccidents } from '../../../../api/insuredApi';
import { useEffect, useState } from 'react';
import { AccidentDetails } from './AccidentDetails';

const StyledTableCell = styled(TableCell)({
    color: 'white',
});

const styles = {
    tableRow: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#8a1218',
        },
    },
};

interface DataTableProps {
    accidentList: Accident[];
    setAccidentList: React.Dispatch<React.SetStateAction<Accident[]>>;
}

export const DataTable = (props: DataTableProps) => {
    const { accidentList, setAccidentList } = props;
    const [filteredAccidents, setFilteredAccidents] = useState<Accident[]>([]);
    const [selectedAccident, setSelectedAccident] = useState<Accident | null>(null);
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        setFilteredAccidents(accidentList);
    }, [accidentList]);

    const handleDelete = async (id: string) => {
        if (window.confirm('¿Estás seguro de que deseas eliminar este siniestro?')) {
            try {
                await deleteAccidentById(id);
                const updatedList = await getAllAccidents();
                setAccidentList(updatedList);
                alert('Siniestro eliminado correctamente');
            } catch (err) {
                console.error('Error al eliminar el siniestro:', err);
                alert('Hubo un error al intentar eliminar el siniestro');
            }
        }
    };

    const handleRowClick = (accident: Accident) => {
        setSelectedAccident(accident);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Número de siniestro</StyledTableCell>
                        <StyledTableCell>Fecha</StyledTableCell>
                        <StyledTableCell>Descripción</StyledTableCell>
                        <StyledTableCell>Estado</StyledTableCell>
                        <StyledTableCell>Asegurado</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredAccidents.map((accident, index) => (
                        <TableRow
                            key={index}
                            sx={styles.tableRow}
                            onClick={() => handleRowClick(accident)}
                        >
                            <TableCell style={{ color: 'white', width: 200 }}>{accident.accidentNumber}</TableCell>
                            <TableCell style={{ color: 'white' }}>{accident.description}</TableCell>
                            <TableCell style={{ color: 'white' }}>{accident.status}</TableCell>
                            <TableCell style={{ color: 'white' }}>{accident.insuredID}</TableCell>
                            <TableCell style={{ color: 'white' }}>{accident.createdAt}</TableCell>
                            <TableCell>
                                <DeleteIcon
                                    sx={{ cursor: 'pointer', color: '#cf1717' }}
                                    onClick={(e) => {
                                        e.stopPropagation(); // Prevent row click event
                                        handleDelete(accident.id);
                                    }}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <AccidentDetails openModal={openModal} handleCloseModal={handleCloseModal} selectedAccident={selectedAccident} />
        </>
    );
};
