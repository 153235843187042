import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { InsuredView } from "./insured/InsuredView";
import { AccidentView } from "./accident/AccidentView";
import { BillingView } from "./billing/BillingView";
import { Accident, Insured } from "../../../api/interfaces/interfaces";
import { getAllAccidents, getAllInsured } from "../../../api/insuredApi";

const StyledTabs = styled(Tabs)({
});

const StyledTab = styled(Tab)({
  padding: "10px 20px",
  textTransform: "none",
  fontWeight: 500,
  "&.Mui-selected": {
    borderBottom: "3px solid #cf1717",
  },
});

export const OfficeView = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [accidentList, setAccidentList] = useState<Accident[]>([]);
  const [insuredList, setInsuredList] = useState<Insured[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accidentData = await getAllAccidents();
        const insuredData = await getAllInsured();
        setAccidentList(accidentData);
        setInsuredList(insuredData);
      } catch (err) {
        setError("Error al cargar los accidentes");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <Container maxWidth="lg" sx={{ marginTop: 4, background: "black", borderRadius: 2, border: '2px solid white' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={4}>
        <Typography variant="h5" fontWeight={300} style={{marginTop: 15, color: 'white'}}>
          Gestión de Oficina
        </Typography>
      </Box>
      <StyledTabs value={tabIndex} onChange={handleTabChange}>
        <StyledTab label="Asegurados" style={{color: 'white'}}/>
        <StyledTab label="Siniestros" style={{color: 'white'}}/>
        <StyledTab label="Facturación" disabled style={{color: 'grey'}}/>
      </StyledTabs>

      <Box marginTop={4}>
        {tabIndex === 0 && <InsuredView
          insuredList={insuredList}
          setInsuredList={setInsuredList}
          loading={loading}
          error={error}
        />}
        {tabIndex === 1 && <AccidentView
          accidentList={accidentList}
          setAccidentList={setAccidentList}
          loading={loading}
          error={error}
        />}
        {tabIndex === 2 && <BillingView />}
      </Box>
    </Container>
  );
};
