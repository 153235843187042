import { useNavigate } from 'react-router-dom';
import NavBar from '../components/navbar/NavBar';
import { checkSessionStatus } from '../api/authApi';
import { useEffect } from 'react';

interface PrivateRouteProps {
	element: JSX.Element;
}
export const PublicRoutes = (props: PrivateRouteProps) => {
	const { element } = props;
	const navigate = useNavigate();

	const checkSession = async () => {
		const { request } = await checkSessionStatus();

		if (request.status === 200) navigate('/');
	};

	useEffect(() => {
		//Control de la session para evitar que si esta autenticado pueda acceder al login.
		if (window.location.pathname === '/login') checkSession();
	});

	return [<NavBar />, element];
};
