import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { Insured } from '../../../../api/interfaces/interfaces';

interface InsuredDetailsProps {
    openModal: boolean;
    handleCloseModal: () => void;
    insuredList: Insured | null;
}

export const InsuredDetails = (props: InsuredDetailsProps) => {
    const { openModal, handleCloseModal, insuredList } = props;

    return (
        <Dialog open={openModal} onClose={handleCloseModal}>
            <Box sx={{ backgroundColor: 'black', color: 'white', width: 500, border: '1px solid #a70d15' }}>
                <DialogTitle>Detalles del Asegurado</DialogTitle>
                <DialogContent>
                    {insuredList && (
                        <>
                            <div><strong>Nombre:</strong> {insuredList.firstname} {insuredList.lastname}</div>
                            <div><strong>Tipo de persona:</strong> {insuredList.personType}</div>
                            <div><strong>Documento:</strong> {insuredList.docType} {insuredList.cuit}</div>
                            <div><strong>Dirección:</strong> {insuredList.street}, {insuredList.city}, {insuredList.province}, {insuredList.country}</div>
                            <div><strong>Piso:</strong> {insuredList.floor || 'N/A'}</div>
                            <div><strong>Teléfono celular:</strong> {insuredList.cellphone}</div>
                            <div><strong>Correo electrónico:</strong> {insuredList.email}</div>
                            <div><strong>Fecha de nacimiento:</strong> {insuredList.birthday}</div>
                            <div><strong>Estado civil:</strong> {insuredList.maritalStatus}</div>
                            <div><strong>Estado:</strong> {insuredList.status}</div>
                            <div><strong>Código postal:</strong> {insuredList.zipcode}</div>
                            <div><strong>Nacionalidad:</strong> {insuredList.nacionality}</div>
                            <div><strong>Fecha de creación:</strong> {insuredList.createdAt}</div>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseModal}
                        sx={{
                            backgroundColor: '#a70d15',
                            color: 'white',
                            marginRight: 4,
                            marginBottom: 2,
                            '&:hover': {
                                backgroundColor: '#8a1218',
                                borderColor: '#8a1218',
                            },
                        }}
                    >
                        Cerrar
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    );
};
