import styled from '@emotion/styled';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Insured } from '../../../../api/interfaces/interfaces';
import { deleteInsuredById, getAllInsured } from '../../../../api/insuredApi';
import { InsuredDetails } from './InsuredDetails';
import { useState } from 'react';


const StyledTableCell = styled(TableCell)({
    color: 'white',
});

const styles = {
    tableRow: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#8a1218',
        },
    },
};

interface DataTableProps {
    insuredList: Insured[];
    setInsuredList: React.Dispatch<React.SetStateAction<Insured[]>>;

}


export const DataTable = (props: DataTableProps) => {
    const { insuredList, setInsuredList } = props;
    const [selectedInsured, setSelectedInsured] = useState<Insured | null>(null);
    const [openModal, setOpenModal] = useState(false);

    const handleDelete = async (id: string) => {
        if (window.confirm('¿Estás seguro de que deseas eliminar este asegurado?')) {
            try {
                await deleteInsuredById(id);
                setInsuredList((prevList) => prevList.filter((insured) => insured.id !== id));
                const updatedList = await getAllInsured();
                setInsuredList(updatedList);
                alert('Asegurado eliminado correctamente');
            } catch (err) {
                console.error('Error al eliminar el asegurado:', err);
                alert('Hubo un error al intentar eliminar el asegurado');
            }
        }
    };

    const handleRowClick = (accident: Insured) => {
        setSelectedInsured(accident);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };


    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Apellido</StyledTableCell>
                        <StyledTableCell>Nombre</StyledTableCell>
                        <StyledTableCell>CUIT</StyledTableCell>
                        <StyledTableCell>Ciudad</StyledTableCell>
                        <StyledTableCell>Celular</StyledTableCell>
                        <StyledTableCell>Correo</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {insuredList.map((insured, index) => (
                        <TableRow
                            key={index}
                            sx={styles.tableRow}
                            onClick={() => handleRowClick(insured)}
                        >
                            <TableCell style={{ color: 'white' }}>{insured.lastname}</TableCell>
                            <TableCell style={{ color: 'white' }}>{insured.firstname}</TableCell>
                            <TableCell style={{ color: 'white' }}>{insured.cuit}</TableCell>
                            <TableCell style={{ color: 'white' }}>{insured.city}</TableCell>
                            <TableCell style={{ color: 'white' }}>{insured.cellphone}</TableCell>
                            <TableCell style={{ color: 'white' }}>{insured.email}</TableCell>
                            <TableCell>
                                <DeleteIcon
                                    sx={{ cursor: 'pointer', color: '#cf1717' }}
                                    onClick={() => handleDelete(insured.id)}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <InsuredDetails openModal={openModal} handleCloseModal={handleCloseModal} insuredList={selectedInsured} />
        </>
    )
}
