import { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  TableContainer,
  Typography,
  Modal,
} from '@mui/material';
import { Insured } from '../../../../api/interfaces/interfaces';
import { CreateInsuredStepper } from './CreateInsuredStepper';
import { StatsBox } from './StatsBox';
import { SearchBox } from './SearchBox';
import { DataTable } from './DataTable';

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "black",
  color:'white',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

interface InsuredViewProps {
  insuredList: Insured[];
  setInsuredList: React.Dispatch<React.SetStateAction<Insured[]>>;
  loading: boolean;
  error: string | null;
}

export const InsuredView = (props: InsuredViewProps) => {
  const { insuredList, setInsuredList, loading, error } = props;
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const activeInsured = insuredList.map(a => a.status === "Activo")

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleOpen}
        sx={{
          position: 'absolute', right: 300, top: 200, backgroundColor: '#a70d15', color: 'white',
          '&:hover': {
            backgroundColor: '#8a1218',
            borderColor: '#8a1218',
          },
        }}
      >
        Nuevo Asegurado
      </Button>

      <Modal open={modalOpen} onClose={handleClose}>
        <Box sx={style}>
          <Typography variant="h6" marginBottom={2}>
            Crear Nuevo Asegurado
          </Typography>

          <Grid container spacing={2}>
            <CreateInsuredStepper handleClose={handleClose} setInsuredList={setInsuredList} />
          </Grid>
        </Box>
      </Modal>

      <Grid container spacing={2} marginTop={2}>
        <StatsBox activeInsured={activeInsured} insuredList={insuredList} />
      </Grid>

      <TableContainer sx={{ marginTop: 4, padding: 2 }}>
        <SearchBox insuredList={insuredList} setInsuredList={setInsuredList} />

        <DataTable insuredList={insuredList} setInsuredList={setInsuredList} />
      </TableContainer>

    </>
  )
}
