import { Button, Grid, Icon, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Accident } from '../../../../api/interfaces/interfaces';
import { createAccident, getAllAccidents } from '../../../../api/insuredApi';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';

interface CreateAccidentProps {
  handleClose: () => void;
  setAccidentList: React.Dispatch<React.SetStateAction<Accident[]>>;
}

const StyledTextField = styled(TextField)(() => ({
  "& .MuiInputLabel-root": {
    color: "white",
    "&.Mui-focused": {
      color: "white",
    },
  },
  "& .MuiOutlinedInput-root": {
    backgroundColor: "black",
    color: "white",
    "& fieldset": {
      borderColor: "#9e1616",
    },
    "&:hover fieldset": {
      borderColor: "#9e1616",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#9e1616",
    },
  },
}));

export const CreateAccidentStepper = (props: CreateAccidentProps) => {
  const [formData, setFormData] = useState({
    accidentNumber: '',
    description: '',
    status: '',
    insuredID: '',
    files: {
      accident: null as string | null,
      repairOrder: null as string | null,
      photos: null as string | null,
    },
    createdAt: new Date(),
  });

  const { handleClose, setAccidentList } = props;
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, files } = event.target;
    if (files && files[0]) {
      const file = files[0];
      setFormData((prev) => ({
        ...prev,
        files: {
          ...prev.files,
          [name]: URL.createObjectURL(file), // Genera una URL para previsualización
        },
      }));
    }
  };


  const validateForm = (): boolean => {
    const newErrors: { [key: string]: string } = {};

    if (!formData.accidentNumber) {
      newErrors.nroDenuncia = "El número de denuncia es requerido.";
    }
    if (!formData.description) {
      newErrors.descripcion = "La descripción es requerida.";
    }
    if (!formData.insuredID) {
      newErrors.asegurado = "El asegurado es requerido.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      const result = await createAccident(formData);

      if (result) {
        alert("Accidente creado exitosamente");
        const updatedList = await getAllAccidents();
        setAccidentList(updatedList);
        handleClose();
      } else {
        alert("Hubo un error al crear el accidente.");
      }
    } else {
      alert("Por favor, corrige los errores en el formulario.");
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "black",
        minWidth: 550,
        minHeight: 500,
        maxHeight: 500,
        padding: 5,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid container spacing={2} sx={{ flex: 1 }}>
        <Grid item xs={6}>
          <StyledTextField
            size="small"
            fullWidth
            label="Número de denuncia"
            name="nroDenuncia"
            value={formData.accidentNumber}
            onChange={handleInputChange}
            error={!!errors.nroDenuncia}
            helperText={errors.nroDenuncia}
          />
        </Grid>
        <Grid item xs={6}>
          <StyledTextField
            size="small"
            fullWidth
            label="Asegurado"
            name="asegurado"
            value={formData.insuredID}
            onChange={handleInputChange}
            error={!!errors.asegurado}
            helperText={errors.asegurado}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledTextField
            size="small"
            fullWidth
            multiline
            rows={4}
            label="Descripción"
            name="descripcion"
            value={formData.description}
            onChange={handleInputChange}
            error={!!errors.descripcion}
            helperText={errors.descripcion}
          />
        </Grid>

        {(["accident", "repairOrder", "photos"] as Array<keyof typeof formData.files>).map((type) => (
          <Grid item xs={12} sm={4} key={type}>
            <Button
              variant="outlined"
              component="label"
              fullWidth
              sx={{
                textTransform: "none",
                fontSize: 14,
                padding: 0,
                paddingTop: .5,
                paddingBottom: .5,
                backgroundColor: "black",
                color: "white",
                borderColor: "red",
                "&:hover": {
                  backgroundColor: "#333",
                  borderColor: "darkred",
                },
              }}
            >
              {type === "accident"
                ? "Denuncia"
                : type === "repairOrder"
                  ? "Orden de reparación"
                  : "Vehículo"}
              <DriveFolderUploadIcon style={{ fontSize: 19, marginLeft: 5 }} />
              <input hidden type="file" name={type} onChange={handleFileChange} />
            </Button>

            <Box
              sx={{
                textAlign: "center",
                backgroundColor: "black",
                padding: 2,
                height: "150px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {formData.files[type] && (
                formData.files[type]?.startsWith("blob:") || formData.files[type]?.includes("data:image") ? (
                  <img
                    src={formData.files[type] as string}
                    alt="Previsualización"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    {formData.files[type]}
                  </Typography>
                )
              )}
            </Box>

          </Grid>
        ))}
      </Grid>

      <Box sx={{ mt: "auto", mb: 2, padding: 0, width: '50%', margin: 'auto' }}>
        <Button
          onClick={handleSubmit}
          variant="contained"
          sx={{
            backgroundColor: "#a70d15",
            color: "white",
            "&:hover": {
              backgroundColor: "#8a1218",
            },
            width: "100%",
          }}
        >
          Crear siniestro
        </Button>
      </Box>
    </Box>
  );
}  
