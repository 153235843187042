import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material'
import { useState } from 'react';
import { PasswordChangeAceptedDialog } from './PasswordChangeAceptedDialog';
import { PasswordChangeDeniedDialog } from './PasswordChangeDeniedDialog';
import { updateUserPassword } from '../../../../../api/userApi';
import { IAccountInformation } from '../../../../../api/interfaces/interfaces';

interface ChangePasswordDialogComponentProps {
	accountInfo: IAccountInformation;
}


export const ChangePasswordDialogComponent = (props : ChangePasswordDialogComponentProps) => {
    // const { accountInfo } = props;
    const [passwordChanged, setPasswordChanged] = useState(false);
    const [passwordDenied, setPasswordDenied] = useState(false);
    const [showDialogPassword, setShowDialogPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
    const handleOpenCloseDialogPassword = () => {
        setShowDialogPassword(!showDialogPassword);
    };
    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };
    const [passwordForm, setPasswordForm] = useState({
        actualPassword: '',
        newPassword: '',
        cofirmNewPassword: '',
    });

    const handleChangePassword = async () => {
        const response = await updateUserPassword(passwordForm.actualPassword, passwordForm.newPassword);
        if (response.status === 201) {
            setPasswordChanged(true);

        } else {
            setPasswordDenied(true);
        }
    };

    ///////// card que aparecen al editar la password
    const handleChangePasswordDialog = () => {
        setPasswordChanged(false);
    };
    const handlePasswordDeniedDialog = () => {
        setPasswordDenied(false);
    };

    console.log(passwordForm.actualPassword + 'actual')
    console.log(passwordForm.cofirmNewPassword + 'confirmed')
    console.log(passwordForm.newPassword + 'new')

    return (

        <>
            <DialogTitle sx={{ color: 'white', fontSize: { xs: '1rem', sm: '1.5rem', whiteSpace: 'nowrap', display: 'flex', justifyContent: 'center' } }}>
                Modificar contraseña
            </DialogTitle>
            <DialogContent>
                <FormControl
                    sx={{
                        borderRadius: '5px',
                        backgroundColor: "#eef3f8",
                        border: '1px solid white', // Borde predeterminado
                        '&:focus-within': {
                            borderColor: '#3F51B5', // Borde azul cuando está enfocado
                        },
                        marginTop: "5%",
                        width: "100%",
                        minWidth: "100%",
                        maxWidth: "100%",

                    }}
                    variant="outlined"
                >
                    <InputLabel
                        sx={{
                            color: 'white', // Color del texto del label
                            opacity: 0.6,
                            '&.Mui-focused': {
                                color: 'white',
                                opacity: 0.6,
                                backgroundColor: '#111111'
                            },
                        }}
                    >Ingresa tu contraseña</InputLabel>
                    <OutlinedInput
                        placeholder="Contraseña actual"
                        sx={{
                            backgroundColor: '#181818',
                            '& input': {
                                color: 'white', // Color del texto
                            },

                        }}
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    sx={{ color: 'white', opacity: 0.6 }}
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Password"
                        onChange={({ target: { value } }) =>
                            setPasswordForm({
                                ...passwordForm,
                                actualPassword: value,
                            })
                        }
                    />
                </FormControl>

                <FormControl
                    sx={{
                        borderRadius: '5px',
                        backgroundColor: "#eef3f8",
                        border: '1px solid white', // Borde predeterminado
                        '&:focus-within': {
                            borderColor: '#3F51B5', // Borde azul cuando está enfocado
                        },
                        marginTop: "5%",
                        width: "100%",
                        minWidth: "100%",
                        maxWidth: "100%",

                    }}
                    variant="outlined"
                >
                    <InputLabel
                        sx={{
                            color: 'white', // Color del texto del label
                            opacity: 0.6,
                            '&.Mui-focused': {
                                color: 'white',
                                opacity: 0.6,
                                backgroundColor: '#111111'
                            },
                        }}
                    >Nueva contraseña</InputLabel>
                    <OutlinedInput
                        placeholder="Nueva contraseña"
                        sx={{
                            backgroundColor: '#181818',
                            '& input': {
                                color: 'white', // Color del texto
                            },

                        }}
                        type={showNewPassword ? "text" : "password"}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    sx={{ color: 'white', opacity: 0.6 }}
                                    onClick={handleClickShowNewPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Password"
                        onChange={({ target: { value } }) =>
                            setPasswordForm({
                                ...passwordForm,
                                newPassword: value,
                            })
                        }
                    />
                </FormControl>


                <FormControl
                    sx={{
                        borderRadius: '5px',
                        backgroundColor: "#eef3f8",
                        border: '1px solid white', // Borde predeterminado
                        '&:focus-within': {
                            borderColor: '#3F51B5', // Borde azul cuando está enfocado
                        },
                        marginTop: "5%",
                        width: "100%",
                        minWidth: "100%",
                        maxWidth: "100%",

                    }}
                    variant="outlined"
                >
                    <InputLabel
                        sx={{
                            color: 'white', // Color del texto del label
                            opacity: 0.6,
                            '&.Mui-focused': {
                                color: 'white',
                                opacity: 0.6,
                                backgroundColor: '#111111'
                            },
                        }}
                    >Confirma tu nueva contraseña</InputLabel>
                    <OutlinedInput
                        placeholder="Confirma tu nueva contraseña"
                        sx={{
                            backgroundColor: '#181818',
                            '& input': {
                                color: 'white', // Color del texto
                            },

                        }}
                        type={showConfirmPassword ? "text" : "password"}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    sx={{ color: 'white', opacity: 0.6 }}
                                    onClick={handleClickShowConfirmPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Password"
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={
                        (passwordForm.actualPassword !== '' || passwordForm.actualPassword.length > 5) &&
                        (passwordForm.newPassword !== '' || passwordForm.newPassword.length > 5)  &&
                        (passwordForm.cofirmNewPassword !== '' || passwordForm.cofirmNewPassword.length > 5)
                         // passwordForm.newPassword !== passwordForm.cofirmNewPassword &&
                        // passwordForm.newPassword.length >= 6 &&
                        // passwordForm.cofirmNewPassword.length >= 6 ||
                        // (passwordForm.newPassword === '' && passwordForm.newPassword.length < 5) &&
                        // (passwordForm.cofirmNewPassword === '' && passwordForm.cofirmNewPassword.length < 5) &&
                        // (passwordForm.actualPassword === '' && passwordForm.actualPassword.length < 5)
                    }
                    onClick={() => {
                        handleChangePassword();
                        handleOpenCloseDialogPassword();
                    }}  
                    sx={{
                        backgroundColor: '#111111',
                        color: 'white',
                        border: '2px inset #B71C1C',
                        width: { xs: '60%', md: '50%' },
                        margin: 'auto',
                        '&.Mui-disabled': {
                            bgcolor: '#111',
                            color: 'white',
                            opacity: 0.5
                        }
                    }}>
                    Guardar
                </Button>
            </DialogActions>
            <Dialog open={passwordChanged} onClose={handleChangePasswordDialog}>
                <PasswordChangeAceptedDialog />
            </Dialog>
            <Dialog open={passwordDenied} onClose={handlePasswordDeniedDialog}>
                <PasswordChangeDeniedDialog />
            </Dialog>

        </>
    )
}
