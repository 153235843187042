import { useCallback, useEffect, useMemo, useState } from "react";
import { UserContext } from "./UserContext";
import Cookies from "universal-cookie";
import axios from "axios";

interface UserProviderProps {
  children: React.ReactNode;
}

export type UserContextType = {
  name: string;
  setName: (value: string) => void;
  token: string;
  setToken: (value: string) => void;
  userID: string;
  setUserID: (value: string) => void;
  userImage: string;
  setUserImage: (value: string) => void;
  setRefreshToken: (value: string) => void;
  refreshToken: string;
  logOut: () => void;
};
const cookies = new Cookies(null, { path: "/" });

const UserProvider = ({ children }: UserProviderProps) => {
  const [token, setToken_] = useState(cookies.get("token"));
  const [refreshToken, setRefreshToken_] = useState(cookies.get("refreshToken"));
  const [name, setName_] = useState(cookies.get("name"));
  const [userImage, setUserImage_] = useState(localStorage.getItem("userImage") ?? '');
  const [userID, setUserID_] = useState(cookies.get("userID"));

  // Function to set the authentication token
  const setToken = (newToken: string) => {
    setToken_(newToken);
  };

  const setRefreshToken = (newToken: string) => {
    setRefreshToken_(newToken);
  };

  const setName = (name: string) => {
    name && cookies.set("name", name);
    setName_(name);
  };

  const setUserID = (id: string) => {
    setUserID_(id);
  };

  const setUserImage = (image: string) => {
    localStorage.setItem("userImage", image);
    setUserImage_(image);
  };

  // const shouldCleanSession = () => {
  //   console.log(token);
  //   console.log(cookies.get("token"));

  //   // if(token !== cookies.get("token"))
  //   //   logOut();
  // }

  const logOut = useCallback(() => {
    cookies.remove("token");
    cookies.remove("refreshToken");
    setToken("");
    setRefreshToken("");
    setName('');
    setUserID('');
    setUserImage('');
  },[]);

  useEffect(() => {
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      cookies.set("token", token);
    }
    if (refreshToken) {
      axios.defaults.headers.common["x-auth-token"] = refreshToken;
      cookies.set("refreshToken", refreshToken);
    }
  }, [token, refreshToken]);

  // useEffect(() => {
  //   if (name) cookies.set("name", name);
  //   else cookies.remove("name");
  //   if (userImage) cookies.set("userImage", userImage);
  //   else cookies.remove("userImage");
  // }, [name, userImage]);

  // useEffect(() => {
	// 	//Esto implementado de esta forma evita multiples peticiones por re-render.
	// 	const intervalId = setInterval(() => shouldCleanSession(), 60000);
	// 	return () => {
	// 		clearInterval(intervalId);
	// 	};
	// }, []);



  // Memoized value of the authentication context
  const contextValue = useMemo(
    () => ({
      name,
      setName,
      refreshToken,
      setRefreshToken,
      token,
      setToken,
      userID,
      setUserID,
      userImage,
      setUserImage,
      logOut,
    }),
    [token, refreshToken, name, userID, userImage, logOut]
  );

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

export default UserProvider;
