import { Grid, Typography } from '@mui/material';

interface SellerRatingProps {
	avgResponseTime: string;
}

export const SellerRating = (props: SellerRatingProps) => {
	const { avgResponseTime } = props;

	const avgResponseTimeToNumber = parseFloat(avgResponseTime !== null ? avgResponseTime : '0');

	function formatoFlexible(value: number) {
		const minutes = Math.floor(value);
		const hours = Math.floor(minutes / 60);
		const days = Math.floor(hours / 24);
		// const weeks = Math.floor(days / 7);
		const remainingHours = hours % 24;
		const remainingMinutes = minutes % 60;
		let result = '';
		if (days > 0) {
			result += days === 1 ? `${days} día ` : `${days} días `;
			if (remainingHours > 0 || remainingMinutes > 0) {
				result += 'y ';
			}
		}
		if (remainingHours > 0) {
			result += remainingHours === 1 ? `${remainingHours} hora ` : `${remainingHours} horas `;
			if (remainingMinutes > 0) {
				result += ' ';
			}
		}
		return result;
	}

	return (
		<Grid container alignItems="center" spacing={1}>
			<Typography
				color="white"
				marginLeft={1}
				sx={{
					fontSize: { xs: '.7rem', sm: '.9rem' },
					width: 'fit-content',
					marginTop: '1.1rem',
					whiteSpace: 'nowrap',
					display: 'flex',
					alignItems: 'flex-start', 
				}}>
				<div>
					<strong>Tiempo promedio de respuesta: </strong> <br />
					{avgResponseTimeToNumber > 0 ? <>{formatoFlexible(avgResponseTimeToNumber)}</> : 'No disponible'}
				</div>
			</Typography>
		</Grid>
	);
};
