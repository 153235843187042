import {
    Box,
    Button,
    Grid,
    TextField,
    MenuItem,
    Select,
    Stepper,
    Step,
    StepLabel,
    SelectChangeEvent,
} from '@mui/material';
import { CreateInsuredData, Insured } from '../../../../api/interfaces/interfaces';
import { createInsured, getAllInsured } from '../../../../api/insuredApi';
import React, { useState } from 'react';
import styled from '@emotion/styled';


interface CreateInsuredStepperProps {
    handleClose: () => void,
    setInsuredList: React.Dispatch<React.SetStateAction<Insured[]>>;
};

const StyledTextField = styled(TextField)(() => ({
    "& .MuiInputLabel-root": {
        color: "white",
        "&.Mui-focused": {
            color: "white",
        },
    },
    "& .MuiOutlinedInput-root": {
        backgroundColor: "black",
        color: "white",
        "& fieldset": {
            borderColor: "#9e1616",
        },
        "&:hover fieldset": {
            borderColor: "#9e1616",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#9e1616",
        },
    },
}));



export const CreateInsuredStepper = (props: CreateInsuredStepperProps) => {
    const { handleClose, setInsuredList } = props;
    const steps = ["Información personal", "Dirección y contacto"];
    const [formData, setFormData] = useState<CreateInsuredData>({
        firstname: "",
        lastname: "",
        email: "",
        personType: "",
        docType: "",
        cuit: "",
        country: "",
        province: "",
        city: "",
        street: "",
        cellphone: "",
        typeIVA: "",
        birthday: "",
        maritalStatus: "",
        nacionality: "",
        zipcode: "",
    });
    const [activeStep, setActiveStep] = useState(0);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSelectChange = (e: SelectChangeEvent<string>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name!]: value }));
    };

    const handleSubmit = async () => {
        if (validateForm()) {
            try {
                const result = await createInsured(formData);

                if (result) {
                    alert("Asegurado creado exitosamente");
                    handleClose();
                    const updatedList = await getAllInsured();
                    setInsuredList(updatedList);
                } else {
                    alert("Hubo un error al crear el asegurado");
                }
            } catch (error) {
                console.error("Error al crear el asegurado:", error);
                alert("Ocurrió un error inesperado. Por favor, inténtalo nuevamente.");
            }
        } else {
            alert("Por favor, corrige los errores en el formulario antes de enviarlo.");
        }
    };


    const validateForm = (): boolean => {
        const newErrors: { [key: string]: string } = {};
      
        if (activeStep === 0) {
          if (!formData.firstname || formData.firstname.trim().length < 2) {
            newErrors.firstname = "El nombre es obligatorio y debe tener al menos 2 caracteres";
          }
      
          if (!formData.lastname || formData.lastname.trim().length < 2) {
            newErrors.lastname = "El apellido es obligatorio y debe tener al menos 2 caracteres";
          }
      
          if (!formData.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            newErrors.email = "El correo electrónico no es válido";
          }
      
          if (!formData.personType) {
            newErrors.personType = "El tipo de persona es obligatorio";
          }
      
          if (!formData.docType) {
            newErrors.docType = "El tipo de documento es obligatorio";
          }
      
          if (!formData.cuit || !/^\d{11}$/.test(formData.cuit)) {
            newErrors.cuit = "El CUIT/DNI debe tener exactamente 11 dígitos";
          }
      
          if (!formData.maritalStatus) {
            newErrors.maritalStatus = "El estado civil es obligatorio";
          }
      
          if (!formData.nacionality) {
            newErrors.nacionality = "La nacionalidad es obligatoria";
          }
      
          if (!formData.birthday) {
            newErrors.birthday = "La fecha de nacimiento es obligatoria";
          } else {
            const birthdayDate = new Date(formData.birthday);
            const age = new Date().getFullYear() - birthdayDate.getFullYear();
            if (isNaN(birthdayDate.getTime()) || age < 18) {
              newErrors.birthday = "Debes ingresar una fecha válida y ser mayor de 18 años";
            }
          }
        }
      
        if (activeStep === 1) {
          if (!formData.country) {
            newErrors.country = "El país es obligatorio";
          }
      
          if (!formData.province) {
            newErrors.province = "La provincia es obligatoria";
          }
      
          if (!formData.city) {
            newErrors.city = "La ciudad es obligatoria";
          }
      
          if (!formData.street) {
            newErrors.street = "La calle es obligatoria";
          }
      
          if (!formData.cellphone || !/^\d{10,15}$/.test(formData.cellphone)) {
            newErrors.cellphone = "El número de celular debe tener entre 10 y 15 dígitos";
          }
      
          if (!formData.typeIVA) {
            newErrors.typeIVA = "El tipo de IVA es obligatorio";
          }
      
          if (!formData.zipcode || !/^\d{4,6}$/.test(formData.zipcode)) {
            newErrors.zipcode = "El código postal debe tener entre 4 y 6 dígitos";
          }
        }
      
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
      };
      

    const handleNext = () => {
        if (validateForm()) {
            setActiveStep((prevStep) => prevStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevStep) => prevStep - 1);
    };

    return (
        <>
            <Stepper
                activeStep={activeStep}
                alternativeLabel
                sx={{
                    backgroundColor: "black",
                    "& .MuiStepLabel-root": {
                        color: "white",
                    },
                    "& .MuiStepLabel-label": {
                        color: "white",
                    },
                    "& .MuiStepLabel-label.Mui-active": {
                        color: "white",
                    },
                    "& .MuiStepLabel-label.Mui-completed": {
                        color: "white",
                    },
                    "& .Mui-disabled .MuiStepLabel-label": {
                        color: "rgba(255, 255, 255, 0.5)",
                    },
                }}
            >
                {steps.map((label, index) => (
                    <Step key={index}>
                        <StepLabel sx={{ color: 'white' }}>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Box sx={{ marginTop: 2, backgroundColor: "black" }}>
                {activeStep === 0 ? (
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <StyledTextField
                                fullWidth
                                label="Nombre"
                                name="firstname"
                                value={formData.firstname}
                                onChange={handleTextFieldChange}
                                size='small'
                                error={!!errors.firstname}
                                helperText={errors.firstname}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <StyledTextField
                                fullWidth
                                label="Apellido"
                                name="lastname"
                                value={formData.lastname}
                                onChange={handleTextFieldChange}
                                size='small'
                                error={!!errors.lastname}
                                helperText={errors.lastname}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <StyledTextField
                                fullWidth
                                label="Email"
                                name="email"
                                value={formData.email}
                                onChange={handleTextFieldChange}
                                size='small'
                                error={!!errors.email}
                                helperText={errors.email}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Select
                                fullWidth
                                name="personType"
                                value={formData.personType}
                                onChange={handleSelectChange}
                                size='small'
                                displayEmpty
                                error={!!errors.personType}
                                sx={{
                                    color: "white",
                                    backgroundColor: "black",
                                    border: "1px solid #9e1616",
                                    ".MuiSelect-icon": {
                                        color: "white",
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#9e1616",
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#9e1616",
                                    },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#9e1616",
                                    },
                                }}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            backgroundColor: "black",
                                            "& .MuiMenuItem-root": {
                                                color: "white",
                                                "&:hover": {
                                                    backgroundColor: "rgba(255, 0, 0, 0.3)",
                                                },
                                            },
                                        },
                                    },
                                }}
                            >
                                <MenuItem value="" disabled>
                                    Tipo de Persona
                                </MenuItem>
                                <MenuItem value="Fisica">Física</MenuItem>
                                <MenuItem value="Juridica">Jurídica</MenuItem>
                            </Select>
                            {errors.personType && <p style={{ color: "red", fontSize: "0.75rem", marginTop: 2 , marginLeft: 10 }}>{errors.personType}</p>}
                        </Grid>
                        <Grid item xs={6}>
                            <Select
                                fullWidth
                                name="docType"
                                value={formData.docType}
                                size='small'
                                onChange={handleSelectChange}
                                displayEmpty
                                error={!!errors.docType}
                                sx={{
                                    color: "white",
                                    backgroundColor: "black",
                                    border: "1px solid #9e1616",
                                    ".MuiSelect-icon": {
                                        color: "white",
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#9e1616",
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#9e1616",
                                    },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#9e1616",
                                    },
                                }}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            backgroundColor: "black",
                                            "& .MuiMenuItem-root": {
                                                color: "white",
                                                "&:hover": {
                                                    backgroundColor: "rgba(255, 0, 0, 0.3)",
                                                },
                                            },
                                        },
                                    },
                                }}
                            >
                                <MenuItem value="" disabled>
                                    Tipo de Documento
                                </MenuItem>
                                <MenuItem value="DNI">DNI</MenuItem>
                                <MenuItem value="CUIT">CUIT</MenuItem>
                            </Select>
                            {errors.docType && <p style={{ color: "red", fontSize: "0.75rem", marginTop: 2 , marginLeft: 10 }}>{errors.docType}</p>}
                        </Grid>
                        <Grid item xs={6}>
                            <StyledTextField
                                fullWidth
                                label="DNI / CUIT"
                                size='small'
                                name="cuit"
                                value={formData.cuit}
                                onChange={handleTextFieldChange}
                                error={!!errors.cuit}
                                helperText={errors.cuit}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <StyledTextField
                                fullWidth
                                size='small'
                                label="Estado Civil"
                                name="maritalStatus"
                                value={formData.maritalStatus}
                                onChange={handleTextFieldChange}
                                error={!!errors.maritalStatus}
                                helperText={errors.maritalStatus}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <StyledTextField
                                fullWidth
                                size='small'
                                label="Nacionalidad"
                                name="nacionality"
                                value={formData.nacionality}
                                error={!!errors.nacionality}
                                helperText={errors.nacionality}
                                onChange={handleTextFieldChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <StyledTextField
                                fullWidth
                                type="date"
                                label="Fecha de Nacimiento"
                                size='small'
                                name="birthday"
                                error={!!errors.birthday}
                                helperText={errors.birthday}
                                value={formData.birthday}
                                onChange={handleTextFieldChange}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <StyledTextField
                                fullWidth
                                size='small'
                                label="País"
                                name="country"
                                value={formData.country} 
                                error={!!errors.country}
                                helperText={errors.country}
                                onChange={handleTextFieldChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <StyledTextField
                                fullWidth
                                size='small'
                                label="Provincia"
                                name="province"
                                value={formData.province}
                                error={!!errors.province}
                                helperText={errors.province}
                                onChange={handleTextFieldChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <StyledTextField
                                size='small'
                                fullWidth
                                label="Ciudad"
                                name="city"
                                value={formData.city}
                                error={!!errors.city}
                                helperText={errors.city}
                                onChange={handleTextFieldChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <StyledTextField
                                size='small'
                                fullWidth
                                label="Calle"
                                name="street"
                                value={formData.street}
                                error={!!errors.street}
                                helperText={errors.street}
                                onChange={handleTextFieldChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <StyledTextField
                                fullWidth
                                size='small'
                                label="Celular"
                                name="cellphone"
                                value={formData.cellphone}
                                error={!!errors.cellphone}
                                helperText={errors.cellphone}
                                onChange={handleTextFieldChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Select
                                size='small'
                                fullWidth
                                name="typeIVA"
                                value={formData.typeIVA}
                                onChange={handleSelectChange}
                                error={!!errors.typeIVA}
                                displayEmpty
                                sx={{
                                    color: "white",
                                    backgroundColor: "black",
                                    border: "1px solid #9e1616",
                                    ".MuiSelect-icon": {
                                        color: "white",
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#9e1616",
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#9e1616",
                                    },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#9e1616",
                                    },
                                }}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            backgroundColor: "black",
                                            "& .MuiMenuItem-root": {
                                                color: "white",
                                                "&:hover": {
                                                    backgroundColor: "rgba(255, 0, 0, 0.3)",
                                                },
                                            },
                                        },
                                    },
                                }}
                            >
                                <MenuItem value="" disabled>Tipo de IVA</MenuItem>
                                <MenuItem value="Responsable Inscripto">Responsable Inscripto</MenuItem>
                                <MenuItem value="Monotributista">Monotributista</MenuItem>
                                <MenuItem value="Exento">Exento</MenuItem>
                            </Select>
                            {errors.typeIVA && <p style={{ color: "red", fontSize: "0.75rem", marginTop: 2 , marginLeft: 10 }}>{errors.typeIVA}</p>}
                        </Grid>
                        <Grid item xs={6}>
                            <StyledTextField
                                fullWidth
                                label="Código Postal"
                                size='small'
                                name="zipcode"
                                value={formData.zipcode}
                                error={!!errors.zipcode}
                                helperText={errors.zipcode}
                                onChange={handleTextFieldChange}
                            />
                        </Grid>
                    </Grid>
                )}

                <Box sx={{ marginTop: 2 }}>
                    <Button sx={{
                        backgroundColor: "#a70d15",
                        color: "white",
                        "&:hover": {
                            backgroundColor: "#8a1218",
                        },
                    }} onClick={handleBack} disabled={activeStep === 0} variant="contained" color="secondary">
                        Atras
                    </Button>
                    {
                        activeStep !== steps.length - 1 &&
                        <Button sx={{
                            marginLeft: -9.5,
                            backgroundColor: "#a70d15",
                            color: "white",
                            "&:hover": {
                                backgroundColor: "#8a1218",
                            },
                        }} onClick={handleNext} variant="contained" >
                            Siguiente
                        </Button>
                    }
                    {activeStep === steps.length - 1 && (
                        <Button onClick={handleSubmit} variant="contained" sx={{
                            marginLeft: 2,
                            backgroundColor: "#a70d15",
                            color: "white",
                            "&:hover": {
                                backgroundColor: "#8a1218",
                            },
                        }}>
                            Crear Asegurado
                        </Button>
                    )}
                </Box>
            </Box>
        </>
    )
}
