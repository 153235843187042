import { CircularProgress, Grid, Typography } from '@mui/material';
import { ProductCard } from '../components/products/ProductCard';
import { IProductsPreview } from '../api/interfaces/interfaces';
import { ProductPreviewSkeleton } from '../components/products/ProductPreviewSkeleton';
import { useEffect, useState, useCallback } from 'react';

interface InitialViewProps {
  productsData: IProductsPreview;
  loading: boolean;
  messageLayout?: string;
}

export const InitialProductsView = (props: InitialViewProps) => {
  const { productsData, loading, messageLayout } = props;
  const { products, totalPages } = productsData;

  const [loadedProducts, setLoadedProducts] = useState(products?.slice(0, 8));
  const [isFetching, setIsFetching] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchMoreProducts = useCallback(() => {
    if (isFetching || currentPage >= totalPages) return;
    setIsFetching(true);
    setTimeout(() => {
      setLoadedProducts(prevProducts => [
        ...prevProducts,
        ...products?.slice(prevProducts.length, prevProducts.length + 8),
      ]);
      setCurrentPage(prevPage => prevPage + 1);
      setIsFetching(false);
    }, 1000); // Simular retraso en la carga
  }, [isFetching, currentPage, totalPages, products]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      const clientHeight = document.documentElement.clientHeight || window.innerHeight;

      if (scrollTop + clientHeight >= scrollHeight - 100 && !isFetching) {
        fetchMoreProducts();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [fetchMoreProducts, isFetching]);

  useEffect(() => {
    setLoadedProducts(products?.slice(0, 8));
  }, [products]);

  return (
    <>
      <Grid
        width="100%"
        container
        sx={{
          height: 'auto',
          backgroundColor: '#111111',
          overflowY: 'hidden',
          mb: '1.5rem',
          pl: { xs: 0, sm: 0, md: '10rem' },
          pr: { xs: 0, sm: 0, md: '10rem' },
        }}
      >
        <Grid item width="100%">
          <Typography
            sx={{
              color: 'white',
              fontSize: '1rem',
              fontFamily: 'Roboto',
              fontWeight: 'bold',
              marginLeft: '1rem',
              marginTop: '1rem',
              marginBottom: '1rem',
            }}
          >
            {messageLayout}:
          </Typography>
        </Grid>

        {!loading
          ? loadedProducts?.map((item, index) => (
            <Grid item key={index} xs={12} sm={6} md={6} lg={4} xl={3} sx={{ marginBottom: '1rem' }}>
              <ProductCard product={item} key={index + 'card'} />
            </Grid>
          ))
          : Array(12)
            .fill(0)
            .map((_, index) => (
              <Grid item key={index} xs={12} sm={6} md={6} lg={4} xl={3} padding={2}>
                <ProductPreviewSkeleton key={index + 'skeleton'} />
              </Grid>
            ))}

        {isFetching && (
          <Grid item xs={12} sx={{ textAlign: 'center', padding: '1rem' }}>
            <Typography sx={{ color: 'white' }}>Cargando más repuestos...</Typography>
			<CircularProgress size={24} color="error" sx={{fontSize:'2rem',mt:'.5rem'}}/>
          </Grid>
        )}
      </Grid>
    </>
  );
};




// bk paginación

// import { Button, Grid, Hidden, Typography } from '@mui/material';
// import { ProductCard } from '../components/products/ProductCard';
// import { IProductsPreview } from '../api/interfaces/interfaces';
// import { ProductPreviewSkeleton } from '../components/products/ProductPreviewSkeleton';
// import { useCallback, useEffect, useState } from 'react';
// import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

// interface InitialViewProps {
// 	productsData: IProductsPreview;
// 	loading: boolean;
// 	messageLayout?: string;
// 	selectedPage: number;
// 	setSelectedPage: (value: number) => void;
// }

// export const InitialProductsView = (props: InitialViewProps) => {
// 	const { productsData, loading, messageLayout, selectedPage, setSelectedPage } = props;
// 	const { products, totalPages } = productsData;

// 	const [values, setValues] = useState<number[]>([]);

// 	useEffect(() => {
// 		const renderPaginateNumberDynamic = (index: number, count: number) => {
// 			// Generate array of pages starting from 1 to totalPages
// 			const pages = Array.from({ length: totalPages }, (_, index) => index + 1);
// 			const half = Math.floor(count / 2);

// 			let start = Math.max(index - half, 1);
// 			let end = Math.min(index + half, totalPages);

// 			// Adjust start and end if they are out of bounds
// 			if (index - half < 1) {
// 				end = Math.min(end + (1 - (index - half)), totalPages);
// 			}

// 			if (index + half > totalPages) {
// 				start = Math.max(start - (index + half - totalPages), 1);
// 			}

// 			return pages.slice(start - 1, end);
// 		};

// 		setValues(renderPaginateNumberDynamic(selectedPage, 4));
// 	}, [totalPages, selectedPage]);

// 	const handleNext = useCallback(() => {
// 		if (selectedPage <= totalPages - 1) setSelectedPage(selectedPage + 1);
// 	}, [totalPages, selectedPage, setSelectedPage]);

// 	const handleBack = useCallback(() => {
// 		if (0 < selectedPage) setSelectedPage(selectedPage - 1);
// 	}, [selectedPage, setSelectedPage]);

// 	return (
// 		<>
// 			<Grid
// 				width="100%"
// 				container
// 				sx={{
// 					minHeight: 'auto',
// 					backgroundColor: '#111111',
// 					overflowY: 'hidden',
// 					mb: '1.5rem',
// 					pl: { xs: 0, sm: 0, md: '10rem' },
// 					pr: { xs: 0, sm: 0, md: '10rem' },
// 				}}>
// 				<Grid item width="100%">
// 					<Typography
// 						sx={{
// 							color: 'white',
// 							fontSize: '1rem',
// 							fontFamily: 'Roboto',
// 							fontWeight: 'bold',
// 							marginLeft: '1rem',
// 							marginTop: '1rem',
// 							marginBottom: '1rem',
// 						}}>
// 						{messageLayout}:
// 					</Typography>
// 				</Grid>

// 				{!loading
// 					? products?.slice(0, 8).map((item, index) => (
// 						<Grid item key={index} xs={12} sm={6} md={6} lg={4} xl={3} sx={{ marginBottom: '1rem' }}>
// 							<ProductCard product={item} key={index + 'card'} />
// 						</Grid>
// 					))
// 					: Array(12)
// 						.fill(0)
// 						.map((i, index) => (
// 							<Grid item key={index} xs={12} sm={6} md={6} lg={4} xl={3} padding={2}>
// 								<ProductPreviewSkeleton key={index + 'skeleton'} />
// 							</Grid>
// 						))}
// 				{values.length > 4 && (
// 					<Grid
// 						xs={12}
// 						sm={9.5}
// 						container
// 						direction="row"
// 						justifyContent="center"
// 						alignItems="center"
// 						sx={{
// 							borderBottom: '.5px inset #B71C1C',
// 							paddingBottom: '.3rem',
// 							backgroundColor: '#111111',
// 							width: { xs: '95%', sm: '60%' },
// 							margin: 'auto',
// 						}}>
// 						<Grid item xs>
// 							<Button
// 								sx={{
// 									color: 'white',
// 									padding: 0,
// 									width: '100%',
// 									'&.Mui-disabled': {
// 										bgcolor: '#111',
// 										color: 'white',
// 										opacity: 0.5,
// 									},
// 								}}
// 								size="small"
// 								onClick={() => {
// 									handleBack();
// 								}}
// 								disabled={values.length === 1 || selectedPage === 1}>
// 								<KeyboardArrowLeft />
// 								<Hidden smDown>{' Anterior'}</Hidden>
// 							</Button>
// 						</Grid>
// 						{values?.map((value, index) => {
// 							return (
// 								<Grid item xs key={index}>
// 									<Button
// 										sx={{
// 											width: '40%',
// 											color: 'white',
// 											border: selectedPage === value ? '1px inset #B71C1C' : 'solid transparent',
// 											cursor: 'pointer',
// 											padding: 0.2,
// 										}}
// 										size="small"
// 										onClick={() => {
// 											setSelectedPage(value);
// 										}}>
// 										{value}
// 									</Button>
// 								</Grid>
// 							);
// 						})}
// 						<Grid item xs>
// 							<Button
// 								size="small"
// 								onClick={() => {
// 									handleNext();
// 								}}
// 								sx={{
// 									color: 'white',
// 									padding: 0,
// 									width: '100%',
// 									'&.Mui-disabled': {
// 										bgcolor: '#111',
// 										color: 'white',
// 										opacity: 0.5,
// 									},
// 								}}
// 								disabled={values.length === 1 || selectedPage === totalPages}>
// 								<Hidden smDown>{'Siguiente '}</Hidden>
// 								<KeyboardArrowRight />
// 							</Button>
// 						</Grid>
// 					</Grid>
// 				)}
// 			</Grid>
// 		</>
// 	);
// };
