import { getProductsBySearch, getProductsPreview, getProductsRecientlyAdded } from './../api/productsApi';
import { useEffect, useState } from 'react';
import { IProductPreview, IProductsPreview } from '../api/interfaces/interfaces';
import { Box } from '@mui/system';
import { InitialProductsView } from './InitialProductsView';
import { Footer } from '../components/footer/Footer';
import { UseGlobalContext } from '../provider/GlobalContext';
import { Card } from '@mui/material';
import banner from '../images/BannerRepuestArg.png';
import { ProductsRecientlyAdded } from '../components/products/recientlyAdded/ProductsRecientlyAdded';

export const Layout = () => {
	const [products, setProducts] = useState<IProductsPreview>();
	const [loading, setLoading] = useState<boolean>(true);
	const [loadingReciently, setLoadingReciently] = useState<boolean>(true);
	const [messageLayout, setMessageLayout] = useState<string>('Productos que pueden interesarte');
	const { searchedText, executeSearch, setSearchedText } = UseGlobalContext();
	const [selectedPage, setSelectedPage] = useState<number>(1);

	const loadProducts = async () => {
		setLoading(true);
		const products = await getProductsPreview(12, selectedPage);
		setProducts(products);
		setLoading(false);
	};
	const [recientlyAddedProducts, setRecientlyAddedProducts] = useState<IProductPreview[]>([]);
	const isMobile = window.innerWidth <= 600;

	const loadrecientlyProducts = async () => {
		setLoadingReciently(true);
		const products = await getProductsRecientlyAdded();
		setRecientlyAddedProducts(products);
		setLoadingReciently(false);
	};

	const handleInputSearch = async () => {
		setMessageLayout('Repuestos basados en tu busqueda');
		setLoading(true);
		await getProductsBySearch(searchedText).then((prods) => {
			setProducts(prods);
			setLoading(false);
		});
		setSearchedText('');
	};

	useEffect(() => {
		loadrecientlyProducts();
	}, []);

	useEffect(() => {
		!searchedText && loadProducts();
	}, [executeSearch, selectedPage]);

	useEffect(() => {
		searchedText && handleInputSearch();
	}, [executeSearch]);

	return (
		<>
			<Box sx={{ overflowY: 'hidden', display: 'flex' }}>
				<Card
					sx={{
						backgroundColor: '#111111',
						fontFamily: 'Roboto',
						fontWeight: 'bold',
						maxHeight: '29vh',
						width: '100vw',
						boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
					}}>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							height: '100%',
							padding: '10px',
							boxSizing: 'border-box',
							pl: { xs: 0, sm: 0, md: '10rem' },
							pr: { xs: 0, sm: 0, md: '10rem' },
						}}>
						<img
							src={banner}
							alt="banenr"
							style={{
								width: '100%', // Establece el ancho de la imagen al 100% del contenedor
								height: '100%', // Establece la altura de la imagen al 100% del contenedor
								// transform: 'scale(1)', // Aplica un efecto de zoom out (escala de 0.8 para reducir el tamaño)
								// transformOrigin: 'center', // Establece el punto de origen de la transformación en el centro de la imagen
							}}
						/>
					</Box>
				</Card>
			</Box>
			<ProductsRecientlyAdded products={recientlyAddedProducts} loading={loadingReciently} isMobile={isMobile} />
			<Box sx={{ display: 'inline', width: '100vw', height: '100vh' }}>
				<InitialProductsView
					productsData={products ?? { products: [], totalPages: 0 }}
					loading={loading}
					messageLayout={messageLayout}
					// selectedPage={selectedPage}
					// setSelectedPage={setSelectedPage}
				/>
			</Box>
			{/* </Grid> */}
			<Footer />
		</>
	);
};
