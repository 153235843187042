import { IVehicleBrand } from "./interfaces/interfaces";
import { axiosService } from "./axiosService";

export const getVehicleBrands = async (): Promise<IVehicleBrand[]> => {
  const data = await axiosService.get(`/vehicleBrands`).then((response)=> {
    if(response.status === 200)
      return response.data;
    else{
      return [];
    }
  }, (err)=> {console.log('Error: ' + err)});
  return data;
};