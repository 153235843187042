import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { StepOne } from './stepOne';
import { StepThree } from './stepThree';
import { useCallback, useState } from 'react';
import { IAlert, IImage, INewProduct, IProduct } from '../../../../api/interfaces/interfaces';
import { StepFour } from './stepFour';
import { createNewProduct, updateProductByID } from '../../../../api/productsApi';
import { UUID } from 'crypto';
import { CustomAlert } from '../../../utils/CustomAlert';
import { ProductDetail } from '../../productDetail/ProductDetail';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Grid } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { StepTwo } from './stepTwo';
interface StepperProps {
	handleSendForm: () => void;
	editedProduct?: INewProduct;
	editedProductId?: UUID;
}

export const VerticalStepper = (props: StepperProps) => {
	const { handleSendForm, editedProduct, editedProductId } = props;
	const [newProduct, setNewProduct] = useState<INewProduct>(
		!!editedProduct
			? {
					name: editedProduct.name,
					description: editedProduct.description,
					images: editedProduct.images,
					price: editedProduct.price,
					categoryID: editedProduct.categoryID,
					vehicleBrandID: editedProduct.vehicleBrandID,
					vehicleModelID: editedProduct.vehicleModelID,
					yearVehicleModel: editedProduct.yearVehicleModel,
					condition: editedProduct.condition,
					state: editedProduct.state,
					city: editedProduct.city,
					buildingType: editedProduct.buildingType,
					buildingCountry: editedProduct.buildingCountry,
					vehicleModelLine: editedProduct.vehicleModelLine,
					specificVehicleModel: editedProduct.specificVehicleModel,
				}
			: {
					name: '',
					description: '',
					images: [],
					price: 0,
					categoryID: null,
					vehicleBrandID: null,
					vehicleModelID: null,
					yearVehicleModel: null,
					condition: null,
					state: null,
					city: null,
					buildingType: null,
					buildingCountry: null,
					vehicleModelLine: null,
					specificVehicleModel: null,
				},
	);
	const [activeStep, setActiveStep] = useState(0);
	const [showError, setShowError] = useState<boolean>(false);
	const [sending, setSending] = useState<boolean>(false);
	const [alert, setAlert] = useState<IAlert>({
		type: 'info',
		message: '',
		show: false,
	});
	const { name, description, price, images, condition, categoryID } = newProduct;

	const handleChangeForm = useCallback(
		(fieldName: keyof INewProduct, value: string | number | IImage[] | string[] | null) => {
			setNewProduct((prevProduct) => ({
				...prevProduct,
				[fieldName]: value,
			}));
		},
		[newProduct, editedProduct],
	);

	const steps = [
		{
			label: 'Información básica del repuesto',
			description: ``,
			component: <StepOne handleChangeForm={handleChangeForm} newProduct={newProduct} />,
		},
		{
			label: 'Fotos del producto',
			description: '',
			component: (
				<StepTwo handleChangeForm={handleChangeForm} newProduct={newProduct} editedProductId={editedProductId!} />
			),
		},
		{
			label: 'Categorización',
			description: ``,
			component: <StepThree handleChangeForm={handleChangeForm} newProduct={newProduct} />,
		},
		{
			label: 'Información adicional',
			description: ``,
			component: <StepFour handleChangeForm={handleChangeForm} newProduct={newProduct} />,
		},
		{
			label: 'Vista previa',
			description: ``,
			component: (
				<Box
					sx={{
						width: { xs: '100%', sm: '45vw' },
						height: '100%',
						maxWidth: '1054px',
						minWidth: '200px',
						border: 'thin solid #D50000',
						backgroundColor: '#111',
					}}>
					<ProductDetail productPreview={newProduct as IProduct} isPreview={true} />
				</Box>
			),
		},
	];

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const stepOneFormValid = useCallback(() => {
		if (name.length > 3 && description.length > 3 && price > 0) return true;
		return false;
	}, [name, description, price]);

	const stepTwoFormValid = useCallback(() => {
		if (images && images?.length > 0) return true;
		return false;
	}, [images]);

	const stepThreeFormValid = useCallback(() => {
		if (categoryID) return true;
		return false;
	}, [categoryID]);

	const stepFourFormValid = useCallback(() => {
		if (condition) return true;
		return false;
	}, [condition]);

	const handleValidationForms = useCallback(
		(index: number) => {
			if (index === 0) {
				if (stepOneFormValid()) return true;
				else return false;
			}
			if (index === 1) {
				if (stepTwoFormValid()) return true;
				else return false;
			}
			if (index === 2) {
				if (stepThreeFormValid()) return true;
				else return false;
			}
			if (index === 3) {
				if (stepFourFormValid()) return true;
				else return false;
			}
			if (index === 4) {
				if (stepOneFormValid() && stepTwoFormValid() && stepThreeFormValid() && stepFourFormValid()) return true;
				else return false;
			}
			return true;
		},
		[stepOneFormValid, stepTwoFormValid, stepThreeFormValid, stepFourFormValid],
	);

	const sendProduct = async () => {
		setSending(true);
		if (!!editedProduct) {
			const { status } = await updateProductByID(editedProductId!, newProduct);
			if (status === 201) {
				setAlert({
					type: 'success',
					message: !!editedProductId ? 'Producto editado con exito!' : 'Producto publicado con exito!',
					show: true,
				});
				handleSendForm();
			} else setShowError(true);
		} else {
			const { status } = await createNewProduct(newProduct);
			if (status === 201) handleSendForm();
			else setShowError(true);
		}
	};

	return (
		<Box sx={{ maxWidth: '90%', ml: '1%' }}>
			<Stepper activeStep={activeStep} orientation="vertical">
				{steps.map((step, index) => (
					<Step key={step.label}>
						<StepLabel
						sx={{cursor: 'pointer'}}
							onClick={() => setActiveStep(index)}
							StepIconProps={{
								icon:
									index === 4 ? (
										<VisibilityIcon />
									) : handleValidationForms(index) ? (
										<CheckCircleIcon style={{ color: 'green' }} />
									) : (
										<CancelIcon style={{ color: 'red' }} />
									),
							}}>
							{step.label}
						</StepLabel>
						<StepContent>{step.component}</StepContent>
					</Step>
				))}
			</Stepper>
			{activeStep === steps.length && (
				<Paper square elevation={0} sx={{ p: 3 }}>
					{showError && (
						<>
							<Typography color="red">Lo sentimos! Hubo un error al publicar el repuesto.</Typography>
							<Typography color="red">Comprueba que hayas completado los campos y vuelve a intentarlo</Typography>
						</>
					)}
					<Button
						onClick={sendProduct}
						sx={{ mt: 1, mr: 1 }}
						variant="contained"
						disabled={showError || sending}
					>
						Publicar!
					</Button>
				</Paper>
			)}
			<Box sx={{ mb: 2 }}>
				<Grid container alignItems={'center'}>
					<Grid item xs={4} width={'70%'}>
						<Button
							// disabled={index === 0}
							variant="contained"
							onClick={handleBack}
							sx={{ mt: 1, right: { sm: '10%', md: '20%', xl: '25%' } }}
							disabled={activeStep <= 0}>
							Atrás
						</Button>
					</Grid>
					<Grid item xs={4}>
						<Typography color={'red'} sx={{fontSize:{xs:'.75rem',sm:'1rem'}}}>Campos obligatorios (*)</Typography>
					</Grid>
					<Grid item xs={4} width={'70%'}>
						{activeStep !== steps.length && (
							<Button
								variant="contained"
								onClick={handleNext}
								sx={{
									mt: 1,
									left: { sm: '26%', md: '35%', xl: '45%' },
								}}
								// disabled={activeStep === steps.length || !handleValidationForms()}
							>
								Siguiente
							</Button>
						)}
					</Grid>
				</Grid>
			</Box>
			{alert.show && <CustomAlert message={alert.message} setAlert={setAlert} type={alert.type} />}
		</Box>
	);
};
